<template>
    <div>
        <div id="app-filter-productlist" class="mt-5 mb-5 filterContainer">
            <div style="margin-top: 1em;">
                <div class="row grid-row ">
                    <div class="col-md-3 mb-2 mb-md-0">
                        <strong class="filterLabel">{{ translationStrings.labelSortiment }}</strong>
                        <v-select :placeholder="translationStrings.placeholderDropdown"
                                  v-model="filter.selectedSortiment" :options="calculatedSortimentOptions"/>
                    </div>
                    <div class="col-md-3 mb-2 mb-md-0">
                        <strong class="filterLabel">{{ translationStrings.labelMaterial }}</strong>
                        <v-select :placeholder="translationStrings.placeholderDropdown"
                                  v-model="filter.selectedMaterial" :options="calculatedMaterialOptions"/>
                    </div>
                    <div class="col-md-3 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-3 mb-2 mb-md-0">
                    </div>
                </div>
            </div>
            <div style="margin-top: 1em;">

                <div class="button-row">
                    <!-- data-container="links-details"  -->
                    <a class="btn mb-2" v-on:click="selectedSection='details'"
                       v-bind:class="{ 'btn-outline-primary': selectedSection!=='details', 'btn-primary': selectedSection==='details' }"
                       style="margin-bottom: 2em; font-size: 1rem" section="details">
                        {{ filterdata.labels.labelProduktdetailseite }} <span
                        class="filterCount">({{ countDetails }})</span>
                    </a>

                    <a class="btn mb-2" v-on:click="selectedSection='katalog'"
                       v-bind:class="{ 'btn-outline-primary': selectedSection!=='katalog', 'btn-primary': selectedSection==='katalog' }"
                       style="margin-bottom: 2em; font-size: 1rem" section="katalog">
                        {{ filterdata.labels.labelKatalogseiten }} <span class="filterCount">({{ countKatalog }})</span>
                    </a>

                    <a class="btn mb-2" v-on:click="selectedSection='montage'"
                       v-bind:class="{ 'btn-outline-primary': selectedSection!=='montage', 'btn-primary': selectedSection==='montage' }"
                       style="margin-bottom: 2em; font-size: 1rem" section="montageanleitungen">
                        {{ filterdata.labels.labelMontageanleitungen }} <span
                        class="filterCount">({{ countMontage }})</span>
                    </a>

                    <a class="btn mb-2" v-on:click="selectedSection='cad'"
                       v-bind:class="{ 'btn-outline-primary': selectedSection!=='cad', 'btn-primary': selectedSection==='cad' }"
                       style="margin-bottom: 2em; font-size: 1rem" section="cad">
                        {{ filterdata.labels.labelCad }} <span class="filterCount">({{ countCad }})</span>
                    </a>

                </div>

                <div v-if="selectedSection==='details'" class="links-details section-div">

                    <a :href="(filterdata.uri + '?__l=' + filterdata.lang + '&__dl=details')" target="_blank"
                       class="btn btn-outline-secondary" style="margin-bottom: 2em; font-size: 1rem" section="details">
                        <span>{{ filterdata.labels.labelDirektlinkButton }}</span>
                    </a>

                    <table class="link-table">
                        <tr>
                            <td style="padding-right: 2em">
                                <strong>{{ filterdata.labels.labelProduktbezeichnung }}</strong></td>
                            <td><strong>{{ filterdata.labels.labelDirektlink }}</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <template v-for="(link, index) in filteredDetailLinks">
                            <tr v-if="filterItemsByTags(link.items).length > 0" :data-groupid="link.groupid"
                                :class="'section-heading heading-' + link.groupid">
                                <td><h4 class="mt-3 mb-2">{{ link.label }}</h4></td>
                                <td></td>
                            </tr>
                            <tr v-for="(linkitem, index2) in filterItemsByTags(link.items)" data-visible="1"
                                :class="'section-content item-' + link.groupid + ' '">
                                <td class="label">{{ removeUnneededParts(linkitem.label) }}</td>
                                <td><a :href="(linkitem.domain + linkitem.url[0])"
                                       target="_blank">{{ linkitem.domain + linkitem.url[0] }}</a></td>
                            </tr>
                        </template>
                    </table>
                </div>

                <div v-if="selectedSection==='montage'" class="links-montageanleitungen section-div">

                    <a :href="(filterdata.uri + '?__l=' + filterdata.lang + '&__dl=montageanleitungen')"
                       class="btn btn-outline-secondary" style="margin-bottom: 2em; font-size: 1rem"
                       section="montageanleitungen">
                        <span>{{ filterdata.labels.labelDirektlinkButton }}</span>
                    </a>

                    <table class="link-table">
                        <tr>
                            <td style="padding-right: 2em">
                                <strong>{{ filterdata.labels.labelProduktbezeichnung }}</strong></td>
                            <td><strong>{{ filterdata.labels.labelDirektlink }}</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <template v-for="(link, index) in filteredMontageLinks">
                            <tr v-if="filterItemsByTags(link.items).length > 0" :data-groupid="link.groupid"
                                :class="'section-heading heading-' + link.groupid">
                                <td><h4 class="mt-3 mb-2">{{ link.label }}</h4></td>
                                <td></td>
                            </tr>
                            <tr v-for="(linkitem, index2) in filterItemsByTags(link.items)" data-visible="1"
                                :class="'section-content item-' + link.groupid + ' '">
                                <td class="label">{{ linkitem.label }}</td>
                                <td><a :href="(linkitem.domain + linkitem.url)"
                                       target="_blank">{{ linkitem.domain + linkitem.url }}</a></td>
                            </tr>
                        </template>


                    </table>
                </div>

                <div v-if="selectedSection==='katalog'" class="links-katalog section-div">

                    <a :href="(filterdata.uri + '?__l=' + filterdata.lang + '&__dl=katalogseiten')"
                       class="btn btn-outline-secondary" style="margin-bottom: 2em; font-size: 1rem" section="katalog">
                        <span>{{ filterdata.labels.labelDirektlinkButton }}</span>
                    </a>

                    <table class="link-table">
                        <tr>
                            <td style="padding-right: 2em">
                                <strong>{{ filterdata.labels.labelProduktbezeichnung }}</strong></td>
                            <td><strong>{{ filterdata.labels.labelDirektlink }}</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <template v-for="(link, index) in filteredKatalogLinks">
                            <tr v-if="filterItemsByTags(link.items).length > 0" :data-groupid="link.groupid"
                                :class="'section-heading heading-' + link.groupid">
                                <td><h4 class="mt-3 mb-2">{{ link.label }}</h4></td>
                                <td></td>
                            </tr>
                            <tr v-for="(linkitem, index2) in filterItemsByTags(link.items)" data-visible="1"
                                :class="'section-content item-' + link.groupid + ' '">
                                <td class="label">{{ linkitem.label }}</td>
                                <td><a :href="(linkitem.domain + linkitem.url)"
                                       target="_blank">{{ linkitem.domain + linkitem.url }}</a></td>
                            </tr>
                        </template>

                    </table>
                </div>


                <div v-if="selectedSection==='cad'" class="links-cad section-div">

                    <a :href="(filterdata.uri + '?__l=' + filterdata.lang + '&__dl=cad')"
                       class="btn btn-outline-secondary" style="margin-bottom: 2em; font-size: 1rem" section="cad">
                        <span>{{ filterdata.labels.labelDirektlinkButton }}</span>
                    </a>
                    <table class="link-table">
                        <tr>
                            <td style="padding-right: 2em">
                                <strong>{{ filterdata.labels.labelProduktbezeichnung }}</strong></td>
                            <td><strong>{{ filterdata.labels.labelDirektlink }}</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <template v-for="(link, index) in filteredCadLinks">
                            <tr v-if="filterItemsByTags(link.items).length > 0" :data-groupid="link.groupid"
                                :class="'section-heading heading-' + link.groupid">
                                <td><h4 class="mt-3 mb-2">{{ link.label }}</h4></td>
                                <td></td>
                            </tr>
                            <tr v-for="(linkitem, index2) in filterItemsByTags(link.items)" data-visible="1"
                                :class="'section-content item-' + link.groupid + ' '">
                                <td class="label">{{ linkitem.label }}</td>
                                <td><a
                                    :href="(filterdata.uri + '?__l=' + filterdata.lang + '&__d=' + linkitem.domain + linkitem.url)"
                                    target="_blank">{{ linkitem.domain + linkitem.url }}</a></td>
                            </tr>
                        </template>
                    </table>
                </div>
                <br/>
                <br/>
            </div>
        </div>
    </div>
</template>
<script>
import _debounce from 'lodash/debounce'
import _flatmap from 'lodash/flatMap'

// https://vue-select.org/api/props.html#options
export default {
    name: 'app',
    components: {},
    props: {
        filterdata: {
            type: Object,
            default: () => {
            }
        },
        translationStrings: {
            type: Object,
            default: () => {
            }
        }
    },
    data: function () {
        return {
            initialized: false,
            selectedSection: 'details',
            filter: {
                selectedSortiment: '',
                selectedMaterial: '',
            },
            defaultFilter: {
                selectedSortiment: '',
                selectedMaterial: '',
            }
        }
    },
    computed: {
        filteredDetailLinks: function () {
            return this.filterdata.linksDetails;
        },
        filteredCadLinks: function () {
            return this.filterdata.linksCad;
        },
        filteredKatalogLinks: function () {
            return this.filterdata.linksKatalog;
        },
        filteredMontageLinks: function () {
            return this.filterdata.linksMontage;
        },
        countDetails: function () {
            let count = 0;
            Object.entries(this.filteredDetailLinks).forEach(_ => {
                count += _[1].items.length;
            });
            return count;
        },
        countCad: function () {
            let count = 0;
            Object.entries(this.filteredCadLinks).forEach(_ => {
                count += _[1].items.length;
            });
            return count;
        },
        countMontage: function () {
            let count = 0;
            Object.entries(this.filteredMontageLinks).forEach(_ => {
                count += _[1].items.length;
            });
            return count;
        },
        countKatalog: function () {
            let count = 0;
            Object.entries(this.filteredKatalogLinks).forEach(_ => {
                count += _[1].items.length;
            });
            return count;
        },
        calculatedSortimentOptions: function () {
            return [
                this.filterdata.labels.amBau,
                this.filterdata.labels.amMoebel,
                this.filterdata.labels.imBau
            ];
            /*
            return _flatmap(this.filterData, function duplicate(n) {
                return n.productRanges;
            }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            */
        },
        calculatedMaterialOptions: function () {
            return [
                this.filterdata.labels.holz,
                this.filterdata.labels.glas,
                this.filterdata.labels.metal
            ];
            /*
            return _flatmap(this.filterData, function duplicate(n) {
                return n.productRanges;
            }).filter((value, index, self) => self.indexOf(value) === index && value !== null).sort();
            */
        },
        filterData_: function () {
            let checker = (arr, target) => target.every(v => arr.includes(v));
            return this.filterdata;
        },
    },
    methods: {
        removeUnneededParts: function (string) {
            return string.split(' - Hawa Sliding Solutions AG').join('');
        },
        filterItemsByTags: function (items) {
            return items.filter((value, index, self) => {
                let tags = []
                if (typeof value.tags === 'object' && value.tags !== null) {
                    // inputObject is an object
                    tags = Object.values(value.tags).filter(value => value !== "")
                } else {
                    // inputObject is not an object
                    tags = value.tags
                }

                return (!this.filter.selectedSortiment || this.filter.selectedSortiment === '' ||
                    tags.indexOf(this.filter.selectedSortiment) !== -1)
            }).filter((value, index, self) => {
                let tags = []
                if (typeof value.tags === 'object' && value.tags !== null) {
                    // inputObject is an object
                    tags = Object.values(value.tags).filter(value => value !== "")
                } else {
                    // inputObject is not an object
                    tags = value.tags
                }
                return (!this.filter.selectedMaterial || this.filter.selectedMaterial === '' ||
                    tags.indexOf(this.filter.selectedMaterial) !== -1)
            });
        },
        resetForm: function () {
            this.filter = {
                selectedSortiment: [],
                selectedSegment: [],
                selectedTopic: [],
                selectedWebinar: [],
                selectedYear: '',
                selectedExpert: '',
                event: 0
            };
            // location.reload();
        },
    },
    watch: {
        /*'filter': {
            deep: true,
            handler: function(newFilter, oldFilter) {
                this.setPathFromFilter();
            }
        },*/
    },
    mounted() {

    }
}
</script>

